<template>
  <b-card>
    <b-card-header>
      <b-card-title>
        {{ $t(title) }}
      </b-card-title>
      <b-button
        v-if="buttonNewReclamation"
        variant="primary"
        @click="$router.push({ name: 'newReclamation'})"
      >
        {{ $t("NEW_RECLAMATION_BUTTON") }}
      </b-button>
    </b-card-header>

    <b-form
      v-if="displayFilter"
      class="mb-5"
    >
      <b-row>
        <b-col md="4">
          <!-- statut -->
          <b-form-group
            :label="$t('RECLAMATION_STATUS')"
          >
            <v-select
              v-model="filter.selectedStatus"
              :options="filter.status"
              :clearable="false"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                {{ $t('VUE_SELECT_NO_DATA') }}
              </template>
              <template #option="{ label }">
                {{ $t(label) }}
              </template>
              <template #selected-option="{label}">
                {{ $t(label) }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- statut -->
        <b-col md="4">
          <b-form-group
            :label="$t('RECLAMATION_TYPE')"
          >
            <v-select
              v-model="filter.selectedType"
              :options="reclamationType"
              :clearable="false"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                {{ $t('VUE_SELECT_NO_DATA') }}
              </template>
              <template #option="{ label }">
                {{ $t(label) }}
              </template>
              <template #selected-option="{label}">
                {{ $t(label) }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- Date creation -->
        <b-col md="4">
          <b-form-group
            :label="$t('RECLAMATION_CREATION_FILTER_FROM')"
          >
            <b-form-datepicker
              v-model="filter.createFrom"
              :local="locale"
              :placeholder="$t('RECLAMATION_CREATION_FILTER_FROM')"
              class="mb-1"
              selected-variant="success"
              reset-button
              reset-value=""
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            :label="$t('RECLAMATION_CREATION_FILTER_TO')"
          >
            <b-form-datepicker
              v-model="filter.createTo"
              :local="locale"
              :placeholder="$t('RECLAMATION_CREATION_FILTER_TO')"
              class="mb-1"
              selected-variant="danger"
              reset-button
              reset-value=""
            />
          </b-form-group>
        </b-col>

        <b-col
          md="2"
        >
          <b-button
            variant="outline-success"
            @click="filterData"
          >
            {{ $t('FILTER_BUTTON') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-overlay
      :show="showOverlay"
      rounded="md"
      variant="transparent"
      opacity="1"
      blur="5px"
    >
      <vue-good-table
        v-if="reclamations && reclamations.length"
        :columns="fields"
        :rows="reclamations"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <!-- Template des colonnes de tableau -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          {{ $t(props.column.label) }}
        </template>

        <!-- Template des données -->
        <template
          slot="table-row"
          slot-scope="data"
        >
          <span
            v-if="data.column.field === 'action'"
            class="text-nowrap"
          >
            <b-button
              v-if="can_access(data.row)"
              variant="outline-info"
              class="btn-icon"
              @click="$router.push({ path: `/reclamation/${data.row.id}`})"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </span>

          <span
            v-else-if="data.column.field === 'id'"
            class="text-nowrap"
          >
            {{ data.row.id }}
          </span>

          <span v-else-if="data.column.field === 'libelle'">
            <div v-if="data.row.title.length<8">
              {{ data.row.title }}
            </div>
            <div v-else>
              {{ data.row.title.substring(0,30)+".." }}
            </div>
          </span>

          <span v-else-if="data.column.field === 'emetteur'">
            {{ data.row.createdBy.firstName }} {{ data.row.createdBy.lastName }}
          </span>

          <span v-else-if="data.column.field === 'type'">
            {{ $t(data.row.reclamationType.name) }} - {{ $t(data.row.reclamationSubType.name) }}
          </span>

          <span v-else-if="data.column.field === 'userTechnique'">
            <template v-if="data.row.userTechnique">
              {{ data.row.userTechnique.firstName }} {{ data.row.userTechnique.lastName }}
            </template>
            <template v-else>
              <center>
                <b-avatar
                  variant="light-danger"
                >
                  <feather-icon icon="SlashIcon" />
                </b-avatar>
              </center>
            </template>
          </span>

          <span v-else-if="data.column.field === 'createdAt'">
            {{ data.row.createdAt | luxon({ output: "dd/MM/y HH:mm" }) }}
          </span>

          <span v-else-if="data.column.field === 'updatedAt'">
            <p v-if="data.row.updatedAt">
              {{ data.row.updatedAt | luxon({ output: "dd/MM/y HH:mm" }) }}
            </p>
          </span>

          <span v-else-if="data.column.field === 'statut'">
            <center>
              <b-badge
                :variant="variantStatut(data.row.status)"
              >
                {{ $t(data.row.status) }}
              </b-badge>
            </center>
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t('PAGINATION_SHOWING') }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','10','25', '50', '100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> {{ $t('PAGINATION_SHOWING_ELEMENT') }} {{ $t('PAGINATION_TOTAL') }} {{ props.total }} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

      </vue-good-table>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay, BRow, BCol, BCard, BCardHeader, BButton, BCardTitle, BBadge, BAvatar, BPagination, BFormSelect, VBTooltip, BForm, BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'

export default {
  components: {
    BOverlay,
    VueGoodTable,
    BRow,
    BCol,
    BAvatar,
    BCard,
    BBadge,
    BCardHeader,
    BButton,
    BCardTitle,
    BPagination,
    BFormSelect,
    vSelect,
    BForm,
    BFormGroup,
    BFormDatepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    buttonNewReclamation: {
      type: Boolean,
      default: () => false,
    },
    displayFilter: {
      type: Boolean,
      default: () => true,
    },
    showOverlay: {
      type: Boolean,
      default: () => true,
    },
    defaultStatus: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      pageLength: 50,
      searchTerm: '',
      fields: [
        { field: 'action', label: '', sortable: false },
        {
          field: 'id', label: 'HEADER_TABLE_NUMBER', sortable: false, type: 'number',
        },
        {
          field: 'libelle',
          label: 'HEADER_TABLE_RECLAMATION_TITLE',
          sortable: false,
        },
        {
          field: 'createdAt', label: 'HEADER_TABLE_CREATED_AT', sortable: false,
        },
        {
          field: 'type', label: 'HEADER_TABLE_RECLAMATION_TYPE', sortable: false,
        },
        {
          field: 'emetteur', label: 'HEADER_TABLE_RECLAMATION_CREATOR', sortable: false,
        },
        {
          field: 'statut', label: 'HEADER_TABLE_RECLAMATION_STATUS', sortable: false,
        },
        {
          field: 'userTechnique', label: 'HEADER_TABLE_RECLAMATION_TECHNINCIAN', sortable: false,
        },
        { field: 'updatedAt', label: 'HEADER_TABLE_UPDATED_AT', sortable: false },
      ],
      filter: {
        selectedStatus: { value: null, label: this.$i18n.t('ALL') },
        status: [
          { value: null, label: 'ALL' },
          { value: 'NEW', label: 'NEW' },
          { value: 'ASSIGNED', label: 'ASSIGNED' },
          { value: 'PROCESSING', label: 'PROCESSING' },
          { value: 'RESOLVED', label: 'RESOLVED' },
          { value: 'CLOSED', label: 'CLOSED' },
        ],
        selectedType: { value: null, label: 'ALL' },
        createFrom: null,
        createTo: null,
      },
    }
  },
  computed: {
    locale() {
      return this.$i18n.locale
    },
    reclamations() {
      return this.$store.getters['reclamations/listReclamations']
    },
    reclamationType() {
      return this.$store.getters['reclamationType/getTypes']
    },
  },
  watch: {
    defaultStatus(value) {
      this.filter.selectedStatus = value
    },
  },
  created() {
    if (this.displayFilter) {
      if (this.$store.getters['reclamationType/getTypes'].length === 0) this.$store.dispatch('reclamationType/fetchAll')
    }
  },
  mounted() {
    if (this.defaultStatus !== null) this.filter.selectedStatus = this.defaultStatus
  },
  methods: {
    filterData() {
      const condition = []
      if (this.filter.selectedStatus.value !== null) condition.status = this.filter.selectedStatus.value
      if (this.filter.selectedType.value !== null) condition.type = this.filter.selectedStatus.value
      if (this.filter.createFrom !== null) condition.createFrom = this.filter.createFrom
      if (this.filter.createTo !== null) condition.createTo = this.filter.createTo
      this.$store.dispatch('reclamations/filter', condition)
    },
    variantStatut(statut) {
      let returnStatut = 'secondary'
      // eslint-disable-next-line default-case
      switch (statut) {
        case 'NEW':
          returnStatut = 'danger'
          break
        case 'ASSIGNED':
        case 'PROCESSING':
          returnStatut = 'warning'
          break
        case 'RESOLVED':
          returnStatut = 'success'
          break
        case 'CLOSED':
          returnStatut = 'danger'
          break
      }
      return returnStatut
    },
    can_access(reclamation) {
      let canAccess = false
      const idCurrentUser = JSON.parse(localStorage.userData).id
      if ((reclamation.userClient && reclamation.userClient.id === idCurrentUser) || (reclamation.userTechnique && reclamation.userTechnique.id === idCurrentUser)) {
        canAccess = true
      }

      if (JSON.parse(localStorage.userData).role === 'superviseur') {
        canAccess = true
      }
      if (JSON.parse(localStorage.userData).company === null) {
        canAccess = true
      }
      canAccess = true
      return canAccess
    },
  },
}
</script>

<style scoped lang="scss">
body.dark-layout .vgt-wrap .vgt-table td span.badge {
  color: #fff;
}
.card-title {
  padding-left:0;
}
</style>
